import { DoneComponent } from './survey/done/done.component';
import { FitComponent } from './survey/fit/fit.component';
import { SetStaticComponent } from './static/set-static/set-static.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';
import { SurveyComponent } from './survey/survey/survey.component';
import { FeedbackComponent } from './survey/feedback/feedback.component';
import { PrivacyAgbComponent } from './privacy/privacy-agb/privacy-agb.component';
import { PrivacyImprintComponent } from './privacy/privacy-imprint/privacy-imprint.component';
import { PrivacyParticipationComponent } from './privacy/privacy-participation/privacy-participation.component';

const routes: Routes = [
  {
    path: 'demographic',
    component: SurveyComponent
  },
  {
    path: '',
    component: FitComponent
  },
  {
    path: 'done',
    component: DoneComponent
  },
  {
    path: 'feedback',
    component: FeedbackComponent
  },
  // {
  //   path: 'static',
  //   component: SetStaticComponent
  // },
  {
    path: 'agb',
    component: PrivacyAgbComponent
  },
  {
    path: 'imprint',
    component: PrivacyImprintComponent
  },
  {
    path: 'participation',
    component: PrivacyParticipationComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Arbeitgeber } from './user/arbeitgeber.model';
import { Skill } from './skill.model';
import { SkillCategory } from './skill-category.Model';
import { isNullOrUndefined, isDate, isNumber, isFunction, isString } from 'util';
import { Role } from './user/role.model';
import { Fit, PersonalFit } from './user/personal-fit.model';
import { Arbeitsort } from './user/arbeitsort.model';
import { Adresse } from './adresse.model';
import { Verfuegbarkeit } from './user/verfuegbarkeit.model';
import { UserDoc } from './user-doc';
import { DataServiceStatic } from '../data.service.static';
import deepClone from '../shared/functions/deepClone';
import deleteUndefined from '../shared/functions/deleteUndefined';
// import { GeocodeResult } from '@googlemaps/google-maps-services-js/dist/common';
import * as firebase from 'firebase/app';
import 'firebase/firestore';


export class PollUser {
    id?: string;

    alter?: number;
    sex?: string;

    berufsfeld?: string;

    beruf_happy? : number = 5;
    beruf_effektiv? : number = 5;
    beruf_diffrentTeam? : number = 5;
    fuehrungserfahrung?:number
    hoechster_abschluss?
    karrierelevel?
    unternehmensgroesse?
    effectiveranderesteam?
    herkunft?
    einkommen: number = 48000;

    anfahrt?: number = 30;

    fit?: [Fit];

    public constructor(init?: Partial<User>) {
        Object.assign(this, init);
    }


    toFirebase() {
        const res: any = deleteUndefined(deepClone(this))
        if (res.adresse?.formatted_address === '') {
            // console.log('del')
            res.adresse = {}
        }

        if (res.jahrgang) {
            res.jahrgang = ((res.jahrgang instanceof Date) ? res.jahrgang : new Date(res.jahrgang)).getFullYear()
        }

        return res as object
    }


}


export class User {
    id?: string;
    vorname: string;
    nachname: string;
    adresse: Adresse = new Adresse();
    email?: string;
    password?: string;
    mobil?: string;
    jahrgang?: Date;
    documents?: UserDoc[];
    // jahrgang?: Date | string;
    notifications?: { email: boolean }
    firstSetup: boolean

    profileUrl?: string;
    personal_fit: PersonalFit = new PersonalFit();
    typ: 'bewerber' | 'pdl' | 'unternehmen'

    roles?: { [key: string]: Role };

    get valid() {
        return Object.keys(this.invalid_fields ?? {}).length === 0
    }

    validators?
    invalid_fields: { [key: string]: Error; }

    geocode?: any
    berufsfeld_temp?: boolean;


    public constructor(init?: Partial<User>) {
        Object.assign(this, init);
        this.adresse = new Adresse(this.adresse || null)
        if (init?.jahrgang) {
            console.log('JGIN', init.jahrgang)
            this.jahrgang = new Date(parseInt(init.jahrgang as any), 1, 1, 0, 0, 0, 0)
            console.log(this.jahrgang)
        }
    }

    toFirebase() {
        const res: any = deleteUndefined(deepClone(this))
        if (res.adresse?.formatted_address === '') {
            // console.log('del')
            res.adresse = {}
        }

        if (res.jahrgang) {
            res.jahrgang = ((res.jahrgang instanceof Date) ? res.jahrgang : new Date(res.jahrgang)).getFullYear()
        }

        return res as object
    }



    public validate(fieldsOnly?: string[]) {
        this.invalid_fields = {}
        const fields = fieldsOnly ?? Object.keys(this)

        for (let field of fields) {
            if (isFunction(this.validators?.[field])) {
                if (!this.validators[field]()) {
                    this.invalid_fields[field] = new Error(`${field} ist ungültig`)
                }
            }
        }
        return this.invalid_fields
    }

}

export class Bewerber extends User {
    europaeischer_staatsbuerger?: boolean
    status: 'aktiv' | 'latent' | 'inaktiv' = 'aktiv'
    privacy: { profileUrl?: 'immer' | 'nach_annahme' } = { profileUrl: null }
    arbeitsorte?: Arbeitsort[] = [];
    berufsfeld?: string;
    berufsfelder?: string[]
    stellenbezeichnung?: string;
    stellenbezeichnungen?: string[]
    arbeitgeber?: Arbeitgeber[] = [new Arbeitgeber()]
    gehalt_aktuell?: number;
    hoechster_abschluss?: string;
    arbeitszeit?: string;
    remote?: boolean;
    anstellungsarten?: string[];
    unternehmensgroesse?: string;
    karrierelevel?: string;
    verfuegbarkeit?: Verfuegbarkeit = new Verfuegbarkeit();
    branchenerfahrung?: string[];
    fuehrungserfahrung?: string;
    sperrvermerke?: string;
    geschlecht?: string;
    blocked?: any
    contacted?: any

    // skills?: Map<string, Skill>
    skill_categories?: SkillCategory[] = [];
    skills_query: { [key: string]: Skill; } = {};
    search_arbeitsorte?: { [key: string]: boolean; } = {}
    search_arbeitsorte_geojson?: any
    score?: number;
    scoreSkills?: number;
    scoreMeta?: number;

    public constructor(init?: Partial<Bewerber>) {
        super(init)
        Object.assign(this, init);

        if (init?.jahrgang) {
            this.jahrgang = new Date(parseInt(init.jahrgang as any), 0, 1, 0, 0, 0, 0)
        }
        this.adresse = new Adresse(this.adresse || null)
        // console.log(init, this)

        if (init?.arbeitgeber) {
            this.arbeitgeber = init.arbeitgeber

            if (this.arbeitgeber?.[0]?.von) {
                const time = this.arbeitgeber[0].von as any
                this.arbeitgeber[0].von = new Date(time.seconds * 1000)
                // }else{
                //     this.arbeitgeber[0].von = new Date
            }

            if (this.arbeitgeber?.[0]?.bis) {
                const time = this.arbeitgeber[0].bis as any
                this.arbeitgeber[0].bis = new Date(time.seconds * 1000)
            }
        } else {
            this.arbeitgeber = [new Arbeitgeber()]
        }

        if (init?.verfuegbarkeit?.datum) {
            const time = this.verfuegbarkeit.datum as any
            this.verfuegbarkeit.datum = new Date(time.seconds * 1000)
        }


        if (init?.contacted) {
            for (let i in init.contacted) {
                this.contacted[i] = new Date(init.contacted[i].seconds * 1000)
            }
        }

        this.validators = {
            vorname: () => !isNullOrUndefined(this.vorname) && this.vorname?.length > 0,
            nachname: () => !isNullOrUndefined(this.nachname) && this.nachname?.length > 0,
            // adresse: () => this.adresse.validate(),
            email: () => !isNullOrUndefined(this.email) && this.email?.length > 0,
            // mobil: () => !isNullOrUndefined(this.mobil) && this.mobil?.length > 0,
            jahrgang: () => isNumber(this.jahrgang) || isDate(this.jahrgang) || isString(this.jahrgang),
            // arbeitsorte: Arbeitsort[] = [new Arbeitsort];
            berufsfeld: () => !isNullOrUndefined(this.berufsfeld) && this.berufsfeld?.length > 0,
            stellenbezeichnung: () => !isNullOrUndefined(this.stellenbezeichnung) && this.stellenbezeichnung?.length > 0,
            // arbeitgeber: Arbeitgeber[] = [new Arbeitgeber]
            gehalt_aktuell: () => isNumber(this.gehalt_aktuell),
            hoechster_abschluss: () => !isNullOrUndefined(this.hoechster_abschluss) && this.hoechster_abschluss?.length > 0,
            // arbeitszeit?: string;
            // remote?: boolean;
            // anstellungsarten?: string[];
            // unternehmensgroesse?: string;
            // karrierelevel?: string;
            verfuegbarkeit: () => !isNullOrUndefined(this.verfuegbarkeit),
            // branchenerfahrung?: string[];
            // fuehrungserfahrung?: string;
            // sperrvermerke?: string;
        }
        // Object.assign(this, init);

    }

    get_search_arbeitsorte_hashes() {
        const search_arbeitsorte: { [key: string]: boolean; } = {}
        this.arbeitsorte.forEach((arbeitsort: Arbeitsort) => arbeitsort.hashes?.forEach(hash => search_arbeitsorte[hash] = true))
        return Object.keys(search_arbeitsorte)
    }

    toFirebase(): object {
        const res = super.toFirebase() as any
        if (!res.arbeitgeber?.[0]?.von) {
            delete res.arbeitgeber?.[0]?.von
        } else {
            res.arbeitgeber[0].von = this.parseFirestoreDate(res.arbeitgeber[0].von)
        }


        if (!res.arbeitgeber?.[0]?.bis) {
            delete res.arbeitgeber?.[0]?.bis
        } else {
            res.arbeitgeber[0].bis = this.parseFirestoreDate(res.arbeitgeber[0].bis)
        }

        if (!res.verfuegbarkeit?.datum) {
            delete res.verfuegbarkeit?.datum
        } else {
            res.verfuegbarkeit.datum = this.parseFirestoreDate(res.verfuegbarkeit.datum)
        }

        delete res.invalid_fields;
        delete res.search_arbeitsorte
        delete res.search_arbeitsorte_geojson

        res.skill_categories.map(x => {
            delete x.search_results
            return x
        })

        if (!res.typ) {
            res.typ = 'bewerber'
        }

        return deleteUndefined(deepClone(res)) as object
    }


    get scoreAll() {
        let score = this.score

        if (this.scoreSkills) {
            score += this.scoreSkills
        }

        if (this.scoreMeta) {
            score += this.scoreMeta
        }

        return score
    }

    getSkillsListed(): string[] {
        let list: string[] = []
        if (this.skill_categories) {
            for (let category of this.skill_categories ?? []) {
                category.sub_categories?.forEach(sub_category => {
                    sub_category.skills?.forEach(skill => {
                        let extras = []
                        if (skill.level) {
                            extras.push(skill.level.name)
                        }
                        skill.sub_skills?.forEach(sub_skill => extras.push(sub_skill.name))
                        list.push(skill.name + ((extras.length > 0) ? ' (' + extras.join(',') + ')' : ''))
                    });
                })
                category.skills?.forEach(skill => {
                    let extras = []
                    if (skill.level) {
                        extras.push(skill.level.name)
                    }
                    skill.sub_skills?.forEach(sub_skill => extras.push(sub_skill.name))
                    list.push(skill.name + ((extras.length > 0) ? ' (' + extras.join(',') + ')' : ''))
                })
            }
        }
        return list;
    }

    getAllSkills(hideCategories: string[]): Skill[] {
        let list = []
        if (this.skill_categories) {
            for (let category of this.skill_categories ?? []) {
                if (!hideCategories || hideCategories.indexOf(category.id) == -1) {
                    category.sub_categories?.forEach(sub_category => {
                        // sub_category.skills?.forEach(skill => list.push({ ...skill, sub_category: { id: sub_category.id, category: { id: category.id } } }));
                        sub_category?.skills?.forEach(skill => list.push(skill));
                    })
                    // category.skills?.forEach(skill => list.push({ ...skill, category: { id: category.id } }))
                    category.skills?.forEach(skill => list.push(skill))
                }
            }
        }
        console.log('allskills', list)
        return list;
    }

    // parseFirestoreDate = (d) => firebase.firestore.Timestamp.fromDate(new Date(d))
    parseFirestoreDate = (d) => new Date(d)

    getBranchenerfahrung = (): string => this.branchenerfahrung?.map(x => DataServiceStatic.staticMap?.branchen?.[x]?.name).join(', ')

    getDocumentDefinition() {
        return {
            content: this.getDocumentDefinitionContent(),
            info: {
                title: this.nachname + '_Profil',
                author: 'Karriereheld',
                subject: 'Bewerber',
            },
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 20, 0, 10],
                    decoration: 'underline'
                },
                name: {
                    fontSize: 16,
                    bold: true
                },
                jobTitle: {
                    fontSize: 14,
                    bold: true,
                    italics: true
                },
                sign: {
                    margin: [0, 50, 0, 10],
                    alignment: 'right',
                    italics: true
                },
                tableHeader: {
                    bold: true,
                }
            }
        };
    }


    getDocumentDefinitionContent = () => [
        {
            text: 'Karriereheld',
            bold: true,
            fontSize: 20,
            alignment: 'center',
            margin: [0, 0, 0, 20]
        },
        {
            columns: [
                [
                    {
                        text: this.nachname,
                        style: 'name'
                    },
                    {
                        // text: '<i class="fas fa-graduation-cap"></i>' +
                        text:
                            DataServiceStatic.staticMap?.hoechster_abschluss[this.hoechster_abschluss]?.name,
                    },
                    {
                        // text: '<i class="fas fa-business-time"></i>' +
                        text:
                            DataServiceStatic.staticMap?.arbeitszeit[this.arbeitszeit]?.name,
                    },
                    {
                        // text: '<i class="fas fa-money-bill-alt"></i>' +
                        text:
                            this.gehalt_aktuell + '€',
                    },
                    {
                        // text: '<i class="fas fa-industry"></i>' +
                        text:
                            this.getBranchenerfahrung(),
                    },
                ],
                [
                    {
                        text: DataServiceStatic.staticMap?.berufsfelder[this.berufsfeld]?.name + ' ' + DataServiceStatic.staticMap?.stellenbezeichnungen[this.stellenbezeichnung]?.name,
                        bold: true
                    },
                    {
                        // text: '<i class="fas fa-level-up-alt"></i>' +
                        text:
                            DataServiceStatic.staticMap?.karrierelevel[this.karrierelevel]?.name,
                    },
                    {
                        // text: '<i class="fas fa-user-tie"></i>' +
                        text:
                            DataServiceStatic.staticMap?.fuehrungserfahrung[this.fuehrungserfahrung]?.name,
                    },
                    {
                        // text: '<i class="fas fa-play-circle"></i>' +
                        text:
                            DataServiceStatic.staticMap?.verfuegbarkeit[this.verfuegbarkeit.id]?.name,
                    }
                ]
            ]
        },
        {
            text: 'Skills',
            style: 'header'
        },
        {
            columns: [
                {
                    ul: [
                        ...this.getSkillsListed().filter((value, index) => index % 3 === 0)
                    ]
                },
                {
                    ul: [
                        ...this.getSkillsListed().filter((value, index) => index % 3 === 1)
                    ]
                },
                {
                    ul: [
                        ...this.getSkillsListed().filter((value, index) => index % 3 === 2)
                    ]
                }
            ]
        },
        {
            columns: [
                { qr: 'https://app.karriereheld.me/search/' + this.id, fit: 100 },
            ]
        }
    ]


}

export class Unternehmen extends User {
    favoriten?: []
    name?: string
    rechtsform?: string
    website?: string
    branche?: string

    public constructor(init?: Partial<User>) {
        super(init)
        this.validators = {
            vorname: () => !isNullOrUndefined(this.vorname) && this.vorname?.length > 0,
            nachname: () => !isNullOrUndefined(this.nachname) && this.nachname?.length > 0,
            name: () => !isNullOrUndefined(this.nachname) && this.nachname?.length > 0,
            email: () => !isNullOrUndefined(this.email) && this.email?.length > 0,
            mobil: () => !isNullOrUndefined(this.mobil) && this.mobil?.length > 0,
        }
        // Object.assign(this, init);
    }
}

export class Personaldienstleister extends User {
    name?: string;
    rechtsform?: string;
    website?: string;
    dienstleistungen?: string[];
    branche?: string

    public constructor(init?: Partial<User>) {
        super(init)

        this.validators = {
            vorname: () => !isNullOrUndefined(this.vorname) && this.vorname?.length > 0,
            nachname: () => !isNullOrUndefined(this.nachname) && this.nachname?.length > 0,
            name: () => !isNullOrUndefined(this.nachname) && this.nachname?.length > 0,
            email: () => !isNullOrUndefined(this.email) && this.email?.length > 0,
            mobil: () => !isNullOrUndefined(this.mobil) && this.mobil?.length > 0,
        }


        //Object.assign(this, init);
    }
}
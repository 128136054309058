<ion-content style="padding-left:10px">
    <p><strong>Zustimmungserkl&auml;rung und Datenschutzhinweis</strong></p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>Freiwilligkeit</strong></p>
    <p>Die Teilnahme an dieser Befragung ist freiwillig (&sect; 6 (1) a DSGVO). Sie k&ouml;nnen</p>
    <p>jederzeit ohne Angabe von Gr&uuml;nden Ihre Teilnahme beenden. Falls Sie sich daf&uuml;r</p>
    <p>entscheiden, nicht an dieser Studie teilzunehmen, wird dies keine nachteiligen</p>
    <p>Auswirkungen auf die Teilnahme an zuk&uuml;nftigen Befragungen haben.</p>
    <p>&nbsp;</p>
    <p><strong>Risiken oder Vorteile</strong></p>
    <p><strong>&nbsp;</strong></p>
    <p>Die Risiken, die sich durch die Teilnahme an dieser Befragung ergeben, sind</p>
    <p>vergleichbar mit den Risiken, die sich aus einer schriftlichen oder telefonischen</p>
    <p>Meinungs&auml;u&szlig;erung und einem Suchprozess im Internet ergeben.</p>
    <p>Mit Ausnahme der Gelegenheit, eine Unterst&uuml;tzung f&uuml;r wissenschaftliche</p>
    <p>Forschung zu leisten, ergeben sich f&uuml;r Sie durch die Teilnahme an dieser</p>
    <p>Befragung keine direkten Vorteile.</p>
    <p>&nbsp;</p>
    <p><strong>Datenschutz Informationen</strong></p>
    <p><strong>&nbsp;</strong></p>
    <p>Der Schutz Ihrer personenbezogenen Daten ist uns ein besonderes Anliegen.</p>
    <p>Ihre personenbezogenen Daten werden von uns nur gem&auml;&szlig; den strengen</p>
    <p>Bestimmungen des deutschen und europ&auml;ischen Datenschutzrechts verarbeitet.</p>
    <p>Ohne Ihre Einwilligung und ohne gesetzliche Grundlage werden wir Ihre</p>
    <p>personenbezogenen Daten nicht weitergeben. Besondere Aufmerksamkeit legen</p>
    <p>wir zudem auf die Umsetzung angemessener Datensicherheitsvorkehrungen,</p>
    <p>insbesondere bei der Verarbeitung besonderer Arten personenbezogener Daten.</p>
    <p>Ihre pers&ouml;nlichen Angaben, Einstellungen und Verlaufsdaten verwenden wir</p>
    <p>ausschlie&szlig;lich zum Zwecke der Studiendurchf&uuml;hrung.</p>
    <p>&nbsp;</p>
    <p><strong>Welche personenbezogenen Daten werden gesammelt und verwendet?</strong></p>
    <p><strong>&nbsp;</strong></p>
    <p>Im Rahmen der Teilnahme an dieser Befragung werden die uns von Ihnen</p>
    <p>mitgeteilten personenbezogenen Daten gespeichert und im Rahmen der</p>
    <p>Durchf&uuml;hrung und Auswertung der Studie entsprechend zu Studienzwecken</p>
    <p>verarbeitet. Im Einzelnen verarbeiten wir folgende Daten zu Ihrer Person</p>
    <p>(entsprechend der Definition von Art. 4 Nr. 1, 13, 14 und 15 DSGVO):</p>
    <p>&nbsp;</p>
    <p>- Pers&ouml;nliche Einstellungen zu den genannten Fragen</p>
    <p>- Zeit f&uuml;r die Beantwortung der Fragen werden gespeichert</p>
    <p>- Pers&ouml;nliche Angaben</p>
    <p>(Geschlecht, Alter, h&ouml;chster Abschluss, Besch&auml;ftigungsverh&auml;ltnis, Fragen zu Team und Beruf, Einkommen, Arbeitsweg)</p>
    <p>&nbsp;</p>
    <p>Es werden aufgrund der festgelegten Zielrichtung der Forschung die oben</p>
    <p>genannten personenbezogenen Daten bei folgenden Personen erhoben und</p>
    <p>verarbeitet (entsprechend der Definition von Art. 4 Nr. 1 DS-GVO)</p>
    <p>- Studierende/r</p>
    <p>- Arbeitnehmer/in</p>
    <p>- Arbeitgeber/in</p>
    <p>- Selbstst&auml;ndige/r</p>
    <p>- Beamte/r</p>
    <p>- Sch&uuml;ler/in</p>
    <p>- Auszubildende/r</p>
    <p>Die Verarbeitung der personenbezogenen Daten f&uuml;r dieses Projekt erfolgt</p>
    <p>aufgrund berechtigter Forschungsinteressen und &ndash;zwecke. Die Teilnahme an</p>
    <p>dieser Befragung ist freiwillig (&sect; 6 (1) a DSGVO). Es werden alle angemessenen</p>
    <p>Schritte zum Schutz Ihrer pers&ouml;nlichen Daten unternommen. Sie werden in keiner</p>
    <p>Ver&ouml;ffentlichung dieser Befragungsergebnisse durch Ihren Namen identifizierbar</p>
    <p>sein.</p>
    <p>Wir sind um die Sicherheit Ihrer Daten im Rahmen der geltenden</p>
    <p>Datenschutzgesetze und technischen M&ouml;glichkeiten maximal bem&uuml;ht.</p>
    <p>Die Daten der Umfrage werden anonymisiert gespeichert.</p>
    <p>Uns ist es nicht m&ouml;glich die Daten Ihrer Person zuzuordnen.</p>
    <p>Die Daten, welche beim Gewinnspiel abgefragt werden, werden unabh&auml;ngig und ohne Verbindung zur Umfrage gespeichert.</p>
    <p>Jede &Uuml;bermittlung von Daten &uuml;ber das Internet beinhaltet das Risiko, dass sich</p>
    <p>unbefugte Fremde Zugriff auf Ihre Daten oder zum Beispiel den Inhalt von Emails</p>
    <p>verschaffen. Ein vollkommener Schutz ist auch bei aller Sorgfalt nicht m&ouml;glich.</p>
    <p>Wir nutzen das Codierungssystems SSL(Secure Socket Layer), weisen jedoch</p>
    <p>darauf hin, dass die Daten&uuml;bertragung im Internet (z.B. bei der Kommunikation</p>
    <p>per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten</p>
    <p>vor dem Zugriff durch Dritte ist nicht m&ouml;glich.</p>
    <p>Zur Sicherung Ihrer Daten unterhalten wir technische- und organisatorische</p>
    <p>Sicherungsma&szlig;nahmen, die wir immer wieder dem Stand der Technik anpassen.</p>
    <p>Wir k&ouml;nnen au&szlig;erdem nicht gew&auml;hrleisten, dass unser Angebot zu jeder Zeiten</p>
    <p>zur Verf&uuml;gung steht; St&ouml;rungen, Unterbrechungen oder Ausf&auml;lle k&ouml;nnen nicht</p>
    <p>ausgeschlossen werden. Die von uns verwendeten Server werden regelm&auml;&szlig;ig</p>
    <p>sorgf&auml;ltig gesichert.</p>
    <p>Eine Daten&uuml;bermittlung in ein Nicht-EU/EEC-Land bzw. an eine internationale</p>
    <p>Organisation findet nicht statt und ist auch nicht geplant.</p>
    <p>&nbsp;</p>
    <p><strong>Rechteinformationen &uuml;ber Datensubjekte</strong></p>
    <p><strong>&nbsp;</strong></p>
    <p>Sie haben das Recht zu erfahren, welche pers&ouml;nlichen Daten uns vorliegen. Mit</p>
    <p>Ihrer Teilnahme an dieser Befragung erkl&auml;ren Sie sich mit der Sammlung,</p>
    <p>Verarbeitung, Weitergabe und Speicherung Ihrer pers&ouml;nlichen Daten (siehe oben)</p>
    <p>im Rahmen der Studie einverstanden.</p>
    <p>Studienverlaufsdaten werden zu Forschungszwecken fortlaufend anonymisiert</p>
    <p>gespeichert. Ver&ouml;ffentlichungen und Publikationen werden nur anonym und ohne</p>
    <p>namentliche Nennung durchgef&uuml;hrt.</p>
    <p>Sie haben das Recht, Auskunft &uuml;ber Sie betreffende Daten der Person oder ein</p>
    <p>von Ihnen verwendetes Pseudonym (&sect; 15 DSGVO) sowie Berichtigung (&sect; 16</p>
    <p>DSGVO), eingeschr&auml;nkte Verarbeitung, L&ouml;schung (Recht auf Vergessenwerden, &sect;</p>
    <p>17 DSGVO) und Sperrung der personenbezogenen Daten (&sect; 18 DSGVO) zu verlangen.</p>
    <p>Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben</p>
    <p>in einem strukturierten, g&auml;ngigen und maschinenlesbaren Format zu erhalten.</p>
    <p>&nbsp;</p>
    <p><strong>Rechteinformationen &uuml;ber den Widerruf der Zustimmung</strong></p>
    <p><strong>&nbsp;</strong></p>
    <p>Eine entsprechende Ansprache an Sie erfolgt aufgrund Forschungs- und</p>
    <p>Entwicklungsinteressen und unter angemessener Ber&uuml;cksichtigung der</p>
    <p>schutzw&uuml;rdigen Betroffenenrechte. Sie k&ouml;nnen jederzeit ohne Angabe von</p>
    <p>Gr&uuml;nden Ihre Teilnahme beenden, von Ihrem Widerspruchsrecht Gebrauch</p>
    <p>machen oder die erteilte Einwilligungserkl&auml;rung mit Wirkung f&uuml;r die Zukunft</p>
    <p>ab&auml;ndern oder g&auml;nzlich widerrufen. Sie k&ouml;nnen einer Kontaktaufnahme per Post</p>
    <p>und/oder E-Mail jederzeit widersprechen und eine ggf. erteilte Einwilligung in die</p>
    <p>Kontaktaufnahme mit Wirkung f&uuml;r die Zukunft jederzeit widerrufen.</p>
    <p>Im Falle des Widerspruchs werden Ihre Kontaktdaten mit angemessener Frist</p>
    <p>nach Widerspruchserkl&auml;rung gel&ouml;scht, sofern keine gesetzlichen</p>
    <p>Aufbewahrungspflichten bestehen. Sie k&ouml;nnen den Widerruf entweder postalisch</p>
    <p>oder per E-Mail an die unter Kontaktdaten genannte Person richten. Es entstehen</p>
    <p>Ihnen dabei keine anderen Kosten als die Portokosten bzw. die</p>
    <p>&Uuml;bermittlungskosten nach den bestehenden Basistarifen.</p>
    <p>Falls Sie sich daf&uuml;r entscheiden, nicht an dieser Studie teilzunehmen, wird dies</p>
    <p>keine nachteiligen Auswirkungen auf die Teilnahme an zuk&uuml;nftigen Befragungen</p>
    <p>haben.</p>
    <p>&nbsp;</p>
    <p><strong>Viel Spa&szlig; w&uuml;nscht das Team von KarriereHeld</strong></p>
</ion-content>
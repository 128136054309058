<ion-content>


    <ion-grid>

        <!-- <canvas #canvas width="100%" height="100%"></canvas> -->




        <div style="padding-top: 50px" class="text-center">
            <ion-row>
                <ion-col size="12" sizeMd="10" offsetMd="1" style="line-height: 1.5;">
                    <h1><i class="fas fa-gift"></i> Danke für deine Zeit!</h1>
                    <p>Wir hoffen, es hat dir Spass gemacht?</p>
                    <h1 class="text-center">Wie haben dir die Fragen gefallen?</h1>
                </ion-col>
                <ion-col offset="1" size="10" class="slider">
                    <ngx-slider (userChangeEnd)="likeSliderDone()" [(value)]="value" [options]="optionsSlider">
                    </ngx-slider>
                </ion-col>
                <ion-col size="12" sizeMd="10" offsetMd="1" style="line-height: 1.5;">
                    <p>Wir würden uns freuen, wenn du uns in ein paar Worten Feedback geben würdest, damit wir den Test in Zukunft angenehmer für alle Teilnehmer gestalten können.</p>
                    <p>Vielen Dank 😉</p>
                    <ui-select routerLink="/feedback"
                    [items]="[{id: 'feedback', name: 'Feedback geben', iconClass: 'fas fa-comments'}]">
                </ui-select>
            </ion-col>


                <ion-col sizeMd="10" offsetMd="1" size="12">
                    Mit deiner Zustimmung nimmst du an der Verlosung von 3 Amazon-Gutscheinen jeweils im Wert von 20 €
                    teil.
                    <br>Ob du gewonnen hast, verraten wir dir ab dem 20.01.2021 Und keine Sorge: deine Daten können
                    nicht dem Test zugewiesen werden

                    <p>
                        <ion-checkbox color="primary" [(ngModel)]="acceptGewinnspiel"></ion-checkbox>&nbsp;Möchtest du
                        am Gewinnspiel teilnehmen und akzeptierst die <a href="https://poll.karriereheld.team/participation"
                            target="_blank">Teilnahmebedingungen</a>
                    </p>
                    <p>
                        <ion-checkbox color="primary" [(ngModel)]="acceptNewsletter"></ion-checkbox>&nbsp;Du willst
                        regelmäßige News rund um Recruiting und unseren Marktstart? Abonniere hier unseren Newsletter,
                        der rund alle 4 Wochen erscheint.
                    </p>
                    <ng-container *ngIf="acceptGewinnspiel || acceptNewsletter || acceptBeta">
                        <ion-input
                            [placeholder]="'Email' +((acceptNewsletter || acceptBeta)?'':' | Telefonnummer | Instagram')"
                            [(ngModel)]="kontaktValue" #kontakt (keydown.enter)="teilnehmen()"
                            (keydown.tab)="teilnehmen()">
                        </ion-input>

                        <!-- <input type="text"> -->
                        <ng-container *ngIf="!loading && !check && kontaktValue != '' ">
                            <ui-select *ngIf="acceptGewinnspiel && !acceptNewsletter && !acceptBeta"
                                [items]="teilnehmenButton"></ui-select>
                            <ui-select *ngIf="!acceptGewinnspiel && (acceptNewsletter || acceptBeta)"
                                [items]="teilnehmenButtonMail"></ui-select>
                            <ui-select *ngIf="acceptGewinnspiel && (acceptNewsletter || acceptBeta)"
                                [items]="teilnehmenButtonBeide"></ui-select>
                        </ng-container>
                        <ui-select *ngIf="loading" [items]="teilnehmenButtonLoading"></ui-select>
                        <ui-select *ngIf="check && !loading" [items]="teilnehmenButtonCheck"></ui-select>
                    </ng-container>
                </ion-col>

            </ion-row>
        </div>
    </ion-grid>
</ion-content>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeWebComponent } from './theme-web/theme-web.component';
import { ThemeSidebarComponent } from './theme-sidebar/theme-sidebar.component';
import { ThemeHeaderComponent } from './theme-header/theme-header.component';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ThemeNoMenuComponent } from './theme-no-menu/theme-no-menu.component';
import { NextTabDirective } from './next-tab.directive';
import { UiComponentsModule } from '../ui-components/ui-components.module';
import { ThemeMenuMobileComponent } from './theme-menu-mobile/theme-menu-mobile.component';



@NgModule({
  declarations: [ThemeWebComponent, ThemeSidebarComponent, ThemeHeaderComponent, ThemeNoMenuComponent, NextTabDirective, ThemeMenuMobileComponent],
  imports: [
    IonicModule,
    CommonModule,
    RouterModule,
  ],
  exports: [
    ThemeSidebarComponent,
    ThemeHeaderComponent,
    NextTabDirective,
  ],
  entryComponents: [
    ThemeMenuMobileComponent,
  ]
})
export class ThemeModule { }

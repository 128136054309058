<ion-content style="padding-left:10px">

    <p><strong>1. Impressum</strong></p>
    <p>Informationspflicht laut &sect; 5 TMG.</p>
    <p>Tim Strakeljahn</p>
    <p>Moosalbstra&szlig;e 17, <br /> 76199 Karlsruhe, <br /> Deutschland</p>
    <p><strong>E-Mail:</strong> <a href="mailto:tim@karriereheld.me">tim@karriereheld.me</a></p>
    <p>&nbsp;</p>
    <p>Quelle: Erstellt&nbsp;mit dem <a href="https://www.adsimple.de/impressum-generator/">Impressum Generator</a> von
        AdSimple&nbsp;in Kooperation mit <a href="https://www.hashtagmann.de">hashtagmann.de</a></p>
    <p><strong>EU-Streitschlichtung</strong></p>
    <p>Gem&auml;&szlig; Verordnung &uuml;ber Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung)
        m&ouml;chten wir Sie &uuml;ber die Online-Streitbeilegungsplattform (OS-Plattform) informieren.<br />
        Verbraucher haben die M&ouml;glichkeit, Beschwerden an die Online Streitbeilegungsplattform der
        Europ&auml;ischen Kommission unter <a
            href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE">http://ec.europa.eu/odr?tid=321244608</a>
        zu richten. Die daf&uuml;r notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.</p>
    <p>Wir m&ouml;chten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an
        Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
    <p><strong>Haftung f&uuml;r Inhalte dieser Website</strong></p>
    <p>Wir entwickeln die Inhalte dieser Webseite st&auml;ndig weiter und bem&uuml;hen uns korrekte und aktuelle
        Informationen bereitzustellen. Laut Telemediengesetz <a
            href="https://www.gesetze-im-internet.de/tmg/__7.html?tid=321244608">(TMG) &sect;7 (1)</a> sind wir als
        Diensteanbieter f&uuml;r eigene Informationen, die wir zur Nutzung bereitstellen, nach den allgemeinen Gesetzen
        verantwortlich. Leider k&ouml;nnen wir keine Haftung f&uuml;r die Korrektheit aller Inhalte auf dieser Webseite
        &uuml;bernehmen, speziell f&uuml;r jene die seitens Dritter bereitgestellt wurden. Als Diensteanbieter im Sinne
        der &sect;&sect; 8 bis 10 sind wir nicht verpflichtet, die von ihnen &uuml;bermittelten oder gespeicherten
        Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit
        hinweisen.</p>
    <p>Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen nach den
        allgemeinen Gesetzen aufgrund von gerichtlichen oder beh&ouml;rdlichen Anordnungen bleiben auch im Falle unserer
        Nichtverantwortlichkeit nach den &sect;&sect; 8 bis 10 unber&uuml;hrt.</p>
    <p>Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren,
        damit wir die rechtswidrigen Inhalte entfernen k&ouml;nnen. Sie finden die Kontaktdaten im Impressum.</p>
    <p><strong>Haftung f&uuml;r Links auf dieser Website</strong></p>
    <p>Unsere Webseite enth&auml;lt Links zu anderen Webseiten f&uuml;r deren Inhalt wir nicht verantwortlich sind.
        Haftung f&uuml;r verlinkte Websites besteht f&uuml;r uns nicht, da wir keine Kenntnis rechtswidriger
        T&auml;tigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir
        Links sofort entfernen w&uuml;rden, wenn uns Rechtswidrigkeiten bekannt werden.</p>
    <p>Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die
        Kontaktdaten im Impressum.</p>
    <p><strong>Urheberrechtshinweis</strong></p>
    <p>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht der Bundesrepublik
        Deutschland. Bitte fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten, vervielf&auml;ltigen oder
        verwerten wie zum Beispiel auf anderen Websites erneut ver&ouml;ffentlichen. Falls notwendig, werden wir die
        unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.</p>
    <p>Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu
        kontaktieren.</p>
    <p><strong>Bildernachweis</strong></p>
    <p>Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich gesch&uuml;tzt.</p>
    <p>Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:</p>
    <ul>
        <li>Fotograf Mustermann</li>
    </ul>
    <p><strong>2. Datenschutzerkl&auml;rung</strong></p>
    <p><strong>Datenschutz</strong></p>
    <p>Wir haben diese Datenschutzerkl&auml;rung (Fassung 26.12.2020-321244608) verfasst, um Ihnen gem&auml;&szlig; der
        Vorgaben der <a
            href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=321244608">Datenschutz-Grundverordnung
            (EU) 2016/679</a> zu erkl&auml;ren, welche Informationen wir sammeln, wie wir Daten verwenden und welche
        Entscheidungsm&ouml;glichkeiten Sie als Besucher dieser Webseite haben.</p>
    <p>Leider liegt es in der Natur der Sache, dass diese Erkl&auml;rungen sehr technisch klingen, wir haben uns bei der
        Erstellung jedoch bem&uuml;ht die wichtigsten Dinge so einfach und klar wie m&ouml;glich zu beschreiben.</p>
    <p><strong>Automatische Datenspeicherung</strong></p>
    <p>Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so
        auch auf dieser Webseite.</p>
    <p>Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem diese Webseite
        gespeichert ist) automatisch Daten wie</p>
    <ul>
        <li>die Adresse (URL) der aufgerufenen Webseite</li>
        <li>Browser und Browserversion</li>
        <li>das verwendete Betriebssystem</li>
        <li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
        <li>den Hostname und die IP-Adresse des Ger&auml;ts von welchem aus zugegriffen wird</li>
        <li>Datum und Uhrzeit</li>
    </ul>
    <p>in Dateien (Webserver-Logfiles).</p>
    <p>In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gel&ouml;scht. Wir geben
        diese Daten nicht weiter, k&ouml;nnen jedoch nicht ausschlie&szlig;en, dass diese Daten beim Vorliegen von
        rechtswidrigem Verhalten eingesehen werden.</p>
    <p><strong>Cookies</strong></p>
    <p>Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu speichern.<br /> Im Folgenden erkl&auml;ren
        wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende Datenschutzerkl&auml;rung besser
        verstehen.</p>
    <p><strong>Was genau sind Cookies?</strong></p>
    <p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise
        Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Webseiten speichern kleine
        Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</p>
    <p>Eines ist nicht von der Hand zu weisen: Cookies sind echt n&uuml;tzliche Helferlein. Fast alle Webseiten
        verwenden&nbsp;Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere&nbsp;Cookies f&uuml;r
        andere Anwendungsbereiche gibt. HTTP-Cookies&nbsp;sind kleine Dateien, die von unserer Website auf Ihrem
        Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem
        &ldquo;Hirn&rdquo; Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der
        Definition eines Cookies m&uuml;ssen zus&auml;tzlich ein oder mehrere Attribute angegeben werden.</p>
    <p>Cookies&nbsp;speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder pers&ouml;nliche
        Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, &uuml;bermittelt Ihr Browser die
        &bdquo;userbezogenen&ldquo; Informationen an unsere Seite zur&uuml;ck. Dank der Cookies wei&szlig; unsere
        Website, wer Sie sind und bietet Ihnen Ihre gewohnte Standardeinstellung. In einigen Browsern hat
        jedes&nbsp;Cookie&nbsp;eine eigene Datei, in anderen wie beispielsweise Firefox sind alle&nbsp;Cookies&nbsp;in
        einer einzigen Datei gespeichert.</p>
    <p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von
        unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google Analytics)
        erstellt.&nbsp;Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die
        Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren.&nbsp;Cookies sind keine
        Software-Programme und enthalten keine Viren, Trojaner oder andere &bdquo;Sch&auml;dlinge&ldquo;. Cookies
        k&ouml;nnen auch nicht auf Informationen Ihres PCs zugreifen.</p>
    <p>So k&ouml;nnen zum Beispiel Cookie-Daten aussehen:</p>
    <ul>
        <li>Name: _ga</li>
        <li>Ablaufzeit: 2 Jahre</li>
        <li>Verwendung: Unterscheidung der Webseitenbesucher</li>
        <li>Beispielhafter Wert:&nbsp;GA1.2.1326744211.152321244608</li>
    </ul>
    <p>Ein Browser sollte folgende Mindestgr&ouml;&szlig;en unterst&uuml;tzen:</p>
    <ul>
        <li>Ein Cookie soll mindestens 4096 Bytes enthalten k&ouml;nnen</li>
        <li>Pro Domain sollen mindestens 50 Cookies gespeichert werden k&ouml;nnen</li>
        <li>Insgesamt sollen mindestens 3000 Cookies gespeichert werden k&ouml;nnen</li>
    </ul>
    <p><strong>Welche Arten von Cookies gibt es?</strong></p>
    <p>Die Frage welche Cookies wir im Speziellen verwenden, h&auml;ngt von den verwendeten Diensten ab und wird in der
        folgenden Abschnitten der Datenschutzerkl&auml;rung gekl&auml;rt. An dieser Stelle m&ouml;chten wir kurz auf die
        verschiedenen Arten von HTTP-Cookies eingehen.</p>
    <p>Man kann 4 Arten von Cookies unterscheiden:</p>
    <p><strong>Unbedingt notwendige Cookies<br /> </strong>Diese Cookies sind n&ouml;tig, um grundlegende Funktionen der
        Website sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt,
        dann auf anderen Seiten weitersurft und sp&auml;ter erst zur Kasse geht. Durch diese Cookies wird der Warenkorb
        nicht gel&ouml;scht, selbst wenn der User sein Browserfenster schlie&szlig;t.</p>
    <p><strong>Funktionelle Cookies<br /> </strong>Diese Cookies sammeln Infos &uuml;ber das Userverhalten und ob der
        User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten
        der Website bei verschiedenen Browsern gemessen.</p>
    <p><strong>Zielorientierte Cookies<br /> </strong>Diese Cookies sorgen f&uuml;r eine bessere Nutzerfreundlichkeit.
        Beispielsweise werden eingegebene Standorte, Schriftgr&ouml;&szlig;en oder Formulardaten gespeichert.</p>
    <p><strong>Werbe-Cookies<br /> </strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem
        User individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>
    <p>&Uuml;blicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt, welche dieser Cookiearten Sie
        zulassen m&ouml;chten. Und nat&uuml;rlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
    <p><strong>Wie kann ich Cookies l&ouml;schen?</strong></p>
    <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabh&auml;ngig von welchem Service oder welcher
        Website die Cookies stammen, haben Sie immer die M&ouml;glichkeit&nbsp;Cookies zu l&ouml;schen, nur teilweise
        zuzulassen oder zu deaktivieren. Zum Beispiel k&ouml;nnen Sie Cookies von Drittanbietern blockieren, aber alle
        anderen Cookies zulassen.</p>
    <p>Wenn Sie feststellen m&ouml;chten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie
        Cookie-Einstellungen &auml;ndern oder l&ouml;schen wollen, k&ouml;nnen Sie dies in Ihren Browser-Einstellungen
        finden:</p>
    <p><a href="https://support.google.com/chrome/answer/95647?tid=321244608">Chrome: Cookies in Chrome l&ouml;schen,
            aktivieren und verwalten</a></p>
    <p><a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321244608">Safari: Verwalten von Cookies
            und Websitedaten mit Safari</a></p>
    <p><a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321244608">Firefox:
            Cookies l&ouml;schen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a></p>
    <p><a
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321244608">Internet
            Explorer: L&ouml;schen und Verwalten von Cookies</a></p>
    <p><a href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321244608">Microsoft Edge:
            L&ouml;schen und Verwalten von Cookies</a></p>
    <p>Falls Sie grunds&auml;tzlich keine Cookies haben wollen, k&ouml;nnen Sie Ihren Browser so einrichten, dass er Sie
        immer informiert, wenn ein Cookie gesetzt werden soll. So k&ouml;nnen Sie bei jedem einzelnen Cookie
        entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten
        ist es Sie suchen die Anleitung in Google mit dem Suchbegriff &ldquo;Cookies l&ouml;schen Chrome&rdquo; oder
        &ldquo;Cookies deaktivieren Chrome&rdquo; im Falle eines Chrome Browsers oder tauschen das Wort
        &ldquo;Chrome&rdquo; gegen den Namen Ihres Browsers, z.B. Edge, Firefox, Safari aus.</p>
    <p><strong>Wie sieht es mit meinem Datenschutz aus?</strong></p>
    <p>Seit 2009 gibt es die sogenannten &bdquo;Cookie-Richtlinien&ldquo;. Darin ist festgehalten, dass das Speichern
        von Cookies eine Einwilligung von Ihnen verlangt. Innerhalb der EU-L&auml;nder gibt es allerdings noch sehr
        unterschiedliche Reaktionen auf diese Richtlinien. In Deutschland wurden die Cookie-Richtlinien nicht als
        nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in &sect; 15 Abs.3
        des Telemediengesetzes (TMG).</p>
    <p>Wenn Sie mehr &uuml;ber Cookies wissen m&ouml;chten und technischen Dokumentationen nicht scheuen, empfehlen
        wir&nbsp;<a href="https://tools.ietf.org/html/rfc6265">https://tools.ietf.org/html/rfc6265</a>, dem Request for
        Comments der Internet Engineering Task Force (IETF) namens &ldquo;HTTP State Management Mechanism&rdquo;.</p>
    <p><strong>Speicherung pers&ouml;nlicher Daten</strong></p>
    <p>Pers&ouml;nliche Daten, die Sie uns auf dieser Website elektronisch &uuml;bermitteln, wie zum Beispiel Name,
        E-Mail-Adresse, Adresse oder andere pers&ouml;nlichen Angaben im Rahmen der &Uuml;bermittlung eines Formulars
        oder Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils
        angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.</p>
    <p>Wir nutzen Ihre pers&ouml;nlichen Daten somit nur f&uuml;r die Kommunikation mit jenen Besuchern, die Kontakt
        ausdr&uuml;cklich w&uuml;nschen und f&uuml;r die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen
        und Produkte. Wir geben Ihre pers&ouml;nlichen Daten ohne Zustimmung nicht weiter, k&ouml;nnen jedoch nicht
        ausschlie&szlig;en, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.</p>
    <p>Wenn Sie uns pers&ouml;nliche Daten per E-Mail schicken &ndash; somit abseits dieser Webseite &ndash; k&ouml;nnen
        wir keine sichere &Uuml;bertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche
        Daten niemals unverschl&uuml;sselt per E-Mail zu &uuml;bermitteln.</p>
    <p>Die Rechtsgrundlage besteht nach <a
            href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=321244608">Artikel
            6&nbsp; Absatz 1 a DSGVO</a> (Rechtm&auml;&szlig;igkeit der Verarbeitung) darin, dass Sie uns die
        Einwilligung zur Verarbeitung der von Ihnen eingegebenen Daten geben. Sie k&ouml;nnen diesen Einwilligung
        jederzeit widerrufen &ndash; eine formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten im Impressum.</p>
    <p><strong>Rechte laut Datenschutzgrundverordnung</strong></p>
    <p>Ihnen stehen laut den Bestimmungen der DSGVO grunds&auml;tzlich die folgende Rechte zu:</p>
    <ul>
        <li>Recht auf Berichtigung (Artikel 16 DSGVO)</li>
        <li>Recht auf L&ouml;schung (&bdquo;Recht auf Vergessenwerden&ldquo;) (Artikel 17 DSGVO)</li>
        <li>Recht auf Einschr&auml;nkung der Verarbeitung (Artikel 18 DSGVO)</li>
        <li>Recht auf Benachrichtigung &ndash; Mitteilungspflicht im Zusammenhang mit der Berichtigung oder
            L&ouml;schung personenbezogener Daten oder der Einschr&auml;nkung der Verarbeitung (Artikel 19 DSGVO)</li>
        <li>Recht auf Daten&uuml;bertragbarkeit (Artikel 20 DSGVO)</li>
        <li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
        <li>Recht, nicht einer ausschlie&szlig;lich auf einer automatisierten Verarbeitung &mdash; einschlie&szlig;lich
            Profiling &mdash; beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)</li>
    </ul>
    <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verst&ouml;&szlig;t oder Ihre
        datenschutzrechtlichen Anspr&uuml;che sonst in einer Weise verletzt worden sind, k&ouml;nnen Sie sich an die <a
            href="https://www.bfdi.bund.de">Bundesbeauftragte f&uuml;r den Datenschutz und die Informationsfreiheit
            (BfDI)</a> wenden.</p>
    <p><strong>Auswertung des Besucherverhaltens</strong></p>
    <p>In der folgenden Datenschutzerkl&auml;rung informieren wir Sie dar&uuml;ber, ob und wie wir Daten Ihres Besuchs
        dieser Website auswerten. Die Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir k&ouml;nnen
        von Ihrem Verhalten auf dieser Website nicht auf Ihre Person schlie&szlig;en.</p>
    <p>Mehr &uuml;ber M&ouml;glichkeiten dieser Auswertung der Besuchsdaten zu widersprechen erfahren Sie in der
        folgenden Datenschutzerkl&auml;rung.</p>
    <p><strong>TLS-Verschl&uuml;sselung mit https</strong></p>
    <p>Wir verwenden https um Daten abh&ouml;rsicher im Internet zu &uuml;bertragen (Datenschutz durch Technikgestaltung
        <a
            href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=321244608">Artikel
            25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer Security), einem
        Verschl&uuml;sselungsprotokoll zur sicheren Daten&uuml;bertragung im Internet k&ouml;nnen wir den Schutz
        vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der Daten&uuml;bertragung am
        kleinen Schlo&szlig;symbol links oben im Browser und der Verwendung des Schemas https (anstatt http) als Teil
        unserer Internetadresse.</p>
    <p>Quelle: Erstellt&nbsp;mit dem&nbsp;<a href="https://www.adsimple.de/datenschutz-generator/">Datenschutz
            Generator</a> von AdSimple&nbsp;in Kooperation mit <a href="https://www.hashtagmann.de">hashtagmann.de</a>
    </p>
    <p>&nbsp;</p>


</ion-content>
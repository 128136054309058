import { Options } from '@angular-slider/ngx-slider';
import { DataService } from '../../data.service';

import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ToastService } from 'src/app/shared/toast.service';

@Component({
	selector: 'app-done',
	templateUrl: './done.component.html',
	styleUrls: ['./done.component.scss'],
})
export class DoneComponent implements OnInit {
	canvas: HTMLCanvasElement;
	confettiCanvas;
	confettiLib;
	celebrate$: Observable<any>;
	destroy = new Subject();
	destroy$ = this.destroy.asObservable();
	check = false


	acceptNewsletter
	acceptGewinnspiel
	acceptBeta
	loading = false
	public kontaktValue = ""
	public isMobile = window.screen.width < 568
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		console.log('ev', event.target.innerWidth)
		this.isMobile = event.target.innerWidth < 568;

		const newOptions: Options = Object.assign({}, this.optionsSlider);
		newOptions.vertical = this.isMobile;
		this.optionsSlider = newOptions;
	}

	constructor(
		@Inject(PLATFORM_ID) private platformID: Object,
		private renderer2: Renderer2,
		private dataService: DataService,
		private elementRef: ElementRef,
		private router: Router,
		private angularFireFunctions: AngularFireFunctions,
		private toastService: ToastService,
		public DataService: DataService
	) { }



	ngOnInit(): void {
		if (isPlatformBrowser(this.platformID)) {
			console.log("GOGO")
			this.celebrate()
		}

		this.dataService.missing.subscribe((res: any) => {
			if (res && !this.dataService.skipMissing) {
				console.log(res)
				if (res.total > 1) {
					if (res.fit.length == 0) {
						this.router.navigate(['/demographic'])
					} else {
						this.router.navigate(['/'])
					}
				}
			}
		})
	}



	public likeSliderDone() {
		this.DataService.savePersonalData("like", this.value);
	}

	ngOnDestroy(): void {
		this.destroy.next();
	}
	async importCanvas(): Promise<any> {
		this.confettiLib = await import('canvas-confetti');
		this.canvas = this.renderer2.createElement('canvas');
	}



	teilnehmenButton = [{
		id: 'teilnehmen', name: 'Teilnehmen', iconClass: "fas fa-gift", onClick: () => { this.teilnehmen() }
	}]

	teilnehmenButtonMail = [{
		id: 'teilnehmen', name: 'Eintragen', iconClass: "fas fa-gift", onClick: () => { this.teilnehmen() }
	}]
	teilnehmenButtonBeide = [{
		id: 'teilnehmen', name: 'Teilnehmen & Eintragen', iconClass: "fas fa-gift", onClick: () => { this.teilnehmen() }
	}]

	teilnehmenButtonLoading = [{
		id: 'teilnehmen', name: 'Senden...', iconClass: "fas fa-spinner-third fa-spin",
	}]

	teilnehmenButtonCheck = [{
		id: 'teilnehmen', name: 'Du nimmst teil', iconClass: "fas fa-check"
	}]

	async teilnehmen() {
		this.loading = true
		const enter = await (this.angularFireFunctions.httpsCallable('enterContest')({ acceptNewsletter: this.acceptNewsletter, acceptBeta: this.acceptBeta, acceptGewinnspiel: this.acceptGewinnspiel, mail: this.kontaktValue }).toPromise().catch(err => {
			console.log('error', err)
			this.loading = false
			this.toastService.error({ title: err })
		}))
		this.loading = false
		if (enter.error) {
			this.toastService.error({ title: enter.error })
			if (enter.error == "Du hast bereits teilgenommen") {
				this.check = true
			} else if (enter.error == 'Es müssen zuerst alle Fragen beantwortet werden') {
				this.router.navigate(['/'])
			}
		} else if (enter.success) {
			this.toastService.success({ title: 'Du wurdest eingetragen. Vielen Dank' })
			this.check = true
		}
		console.log("TEILNEHMEN", enter)
	}


	public value: number = 5;
	public optionsSlider: Options = {
		showTicksValues: true,
		vertical: this.isMobile,
		stepsArray: [
			{ value: 1, legend: "Gar nicht" },
			{ value: 2 },
			{ value: 3, legend: "Okay" },
			{ value: 4 },
			{ value: 5, legend: "Normal" },
			{ value: 6 },
			{ value: 7, legend: "Gut" },
			{ value: 8 },
			{ value: 9, legend: "Sehr" }
		]
	};

	celebrate(): void {
		console.log("clelebrate")
		let checkCanvasConfettiExists = async () => Promise.resolve(); // set this to resolve regardless if confetti already exists
		if (!this.confettiCanvas) { // if not already imported, replace with importing function 
			checkCanvasConfettiExists = this.importCanvas;
		}
		checkCanvasConfettiExists.call(this) // bind to 'this' as the importCanvas function will need the correct 'this'
			.then(() => {
				this.renderer2.appendChild(this.elementRef.nativeElement, this.canvas); // append the canvas

				this.confettiCanvas = this.confettiLib.create(this.canvas, { resize: true }); // create the confetti canvas
				const end = Date.now() + (5 * 1000); // set the end time
				const interval = setInterval(() => {
					if (Date.now() > end) { // if time reached then clear the interval
						clearInterval(interval);

						return //this.renderer2.removeChild(this.elementRef.nativeElement, this.canvas); // remove the canvas from the DOM
					}
					var colors = ['#FF1D3F', '#FFB400', '#FFFFFF'];

					this.confettiCanvas({ // if time hasn't passed then call the start the confetti
						startVelocity: 30,
						spread: 360,
						ticks: 60,
						shapes: ['square', 'circle'],
						colors: colors,
						origin: {
							x: Math.random(),
							// since they fall down, start a bit higher than random
							y: Math.random() - 0.2
						}
					});

				}, 500);
			});
	}




}
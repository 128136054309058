import { Component, OnInit, Input, Output, EventEmitter, OnChanges, NgZone } from '@angular/core';
import { DomController } from '@ionic/angular';

@Component({
  selector: 'ui-select',
  templateUrl: './ui-select.component.html',
  styleUrls: ['./ui-select.component.scss'],
})
export class UiSelectComponent implements OnInit, OnChanges {
  @Input() type: 'radio' | 'checkbox' = 'radio';
  @Input() maxCheckbox: number;

  //Eigene Bezeichnung erlauben
  @Input() custom: boolean;
  @Input() customMap: object;
  
  @Input() search: boolean;
  @Input() fullWidth: boolean;

  @Input() allSmall: boolean;
  @Input() mini: boolean;

  @Input() items: { id: string, name: string, info: string, onClick: Function, active: boolean, iconClass?:string, image?:string }[];
  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  public customDefault;
  public searchTerm;
  public results

  constructor(private ngZone: NgZone, private domCtrl: DomController) { }

  ngOnInit() {
    console.log('uiSelect', this.items)
    this.customDefault = this.customMap?.[this.value]?.name
    this.items?.forEach(x => x.active = this.isActive(x))
    this.results = this.items ?? []
  }

  ngOnChanges() {
    console.log('changed', this.items)
    this.items?.forEach(x => x.active = this.isActive(x))
    this.results = this.items ?? []
  }

  isArray = (i) => Array.isArray(i)

  select(value) {
    if (value.onClick) {
      value.onClick()
    } else if (this.type == 'radio') {
      this.value = value.id
    } else if (this.type == 'checkbox') {
      if (!this.value || !(this.value instanceof Array)) {
        this.value = []
      }
      if (!value.active) {
        if(this.maxCheckbox && this.maxCheckbox <= this.value.length){
          this.value.shift()
        }
        this.value.push(value.id)
      } else {
        this.value = this.value.filter(x => x != value.id)
      }
    }
    this.valueChange.emit(this.value)
    this.results.forEach(x => x.active = this.isActive(x))
    this.customDefault = this.customMap?.[value.id]?.name
  }

  textFieldChange() {
    // if (this.search) {
    if (!this.customDefault) {
      this.results = this.items
    }
    this.domCtrl.write(() => this.results = this.items?.filter(x => (x.name.toLowerCase().indexOf(this.customDefault.toLowerCase()) > -1))??[])
    // }else{
    //   this.results.forEach(x => x.active = false)
    // }
    // console.log(this.results)
    this.valueChange.emit(this.customDefault)
  }


  isActive(value) {
    switch (this.type) {
      case 'checkbox':
        return Array.isArray(this.value) ? this.value.indexOf(value.id) > -1 : false
      case 'radio':
      default:
        return this.value == value.id
    }
  }
}

<ion-content>
  <h1>Mehr...</h1>
  <ion-list>
    <ion-item>
      <ion-label>Kontakt</ion-label>
    </ion-item>
    <ion-item>
      <ion-label>Impressum</ion-label>
    </ion-item>
    <ion-item *ngIf="show" (click)="logout()">
      <i class="fas fa-sign-out-alt" slot="start"></i>
      <ion-label>Logout</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
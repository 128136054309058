import { Router } from '@angular/router';
import { Constants } from './../../data.service';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DataService } from 'src/app/data.service';
import { ToastService } from 'src/app/shared/toast.service';
import { Bewerber, PollUser } from 'src/app/models/user.model';
import { NavController, ModalController } from '@ionic/angular';
import * as _moment from 'moment';
import { unitOfTime } from 'moment';
import { DateButton } from 'angular-bootstrap-datetimepicker';
import { take } from 'rxjs/operators';
import { Options } from '@angular-slider/ngx-slider';
import { CONTEXT_NAME } from '@angular/compiler/src/render3/view/util';


let moment = _moment;

if ('default' in _moment) {
  moment = _moment['default'];
}

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent implements OnInit {
  @ViewChild('slides') slides;
  @ViewChild('alter', { static: false }) inputEl: any;
  @ViewChild('pageTop') pageTop;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    console.log('ev', event.target.innerWidth)
    this.isMobile = event.target.innerWidth < 568;

    const newOptionsAnfahrt: Options = Object.assign({}, this.optionsAnfahrt);
    newOptionsAnfahrt.vertical = this.isMobile;
    this.optionsAnfahrt = newOptionsAnfahrt;

    const newOptionsEinkommen: Options = Object.assign({}, this.optionsEinkommen);
    newOptionsEinkommen.vertical = this.isMobile;
    this.optionsEinkommen = newOptionsEinkommen;

    const newOptions: Options = Object.assign({}, this.optionsSlider);
    newOptions.vertical = this.isMobile;
    this.optionsSlider = newOptions;

    const newOptionsDiff: Options = Object.assign({}, this.optionsSliderDiffrent);
    newOptions.vertical = this.isMobile;
    this.optionsSliderDiffrent = newOptionsDiff;


  }

  public user: PollUser;
  public userId: Bewerber;
  // public currentPage = 'name'
  public currentPageIndex = 0;
  public currentPageId = 'sex';
  public submittedOnce: boolean = false;
  public done: boolean = false;
  public doneOpt = [
    {
      id: 'home', name: 'Start', iconClass: "fas fa-home",
      onClick: () => this.navCtrl.navigateRoot('/')
    },
    {
      id: 'profil', name: 'Profil', iconClass: "fas fa-user",
      onClick: () => this.navCtrl.navigateRoot('/user/profile')
    },
  ]

  addToAlter(number) {
    if (!this.user.alter) {
      this.user.alter = 0
    }
    this.user.alter *= 10
    this.user.alter += number
  }


  public map;
  public typingTimer;
  public loading;

  public static_skill_categories;
  public isMobile = window.screen.width < 568



  public workSpeed = {
    timer: 5,
    interval: null,
  }

  // public form: FormGroupC

  public pages = [
    {
      id: 'sex',
      name: 'Geschlecht',
      icon: 'fas fa-genderless',
      title: 'Welches Geschlecht hast du?',
      index: 0,
      hideMobile: true,
    },
    {
      id: 'jahrgang',
      name: 'Jahrgang',
      icon: 'fas fa-calendar',
      index: 1,
      // focus: 'jahrgang',
      fields: ['jahrgang'],
      hideMobile: true,
    },
    {
      id: 'erfahrungen',
      name: 'Erfahrungen',
      icon: 'fas fa-graduation-cap',
      index: 2,
      focus: 'hoechster_abschluss',
      hideMobile: false,
    },
    {
      id: 'berufsfeld',
      name: 'Berufsfeld',
      icon: 'fas fa-caret-up',
      index: 3,
      focus: 'berufsfeld',
    },
    {
      id: 'beruf_happy',
      name: 'Beruf Happy',
      icon: 'fas fa-caret-up',
      index: 4
    },
    {
      id: 'beruf_diffrentTeam',
      name: 'Beruf anderes Team',
      icon: 'fas fa-caret-up',
      index: 5
    },
    {
      id: 'beruf_effektiv',
      name: 'Beruf Effektiv',
      icon: 'fas fa-caret-up',
      index: 6
    },

    {
      id: 'effectiveranderesteam',
      name: 'Anderes Team effekiver',
      icon: 'fas fa-users',
      index: 7
    },

    {
      id: 'herkunft',
      name: 'Herkunft',
      icon: 'fas fa-home-lg',
      index: 8
    },

    {
      id: 'einkommen',
      name: 'Einkommen',
      icon: 'fas fa-money-bill-wave',
      index: 9
    },
    {
      id: 'anfahrt',
      name: 'Anfahrt',
      icon: 'far fa-subway',
      index: 10
    },
  ];


  public pagesMap = this.DataService.arrayToObject(this.pages);

  constructor(
    public DataService: DataService,
    private ToastService: ToastService,
    private navCtrl: NavController,
    private router: Router,
    public modalController: ModalController,
  ) {
    console.log('WDITH', window.outerWidth)
  }



  public sexMap;



  public setupDemopgrah() {

    this.sexMap = {
      id: "sex",
      question: "Wie ist dein Geschlecht=",
      answers: [
        {
          id: "m",
          name: "Männlich",
          // onClick: () => this.selectedItem("sex", "m")
        },
        {
          id: "w",
          name: "Weiblich",
          // onClick: () => this.selectedItem("sex", "w")
        },
        {
          id: "d",
          name: "Divers",
          // onClick: () => this.selectedItem("sex", "d")
        }
      ]
    }
  }


  public value: number = 5;
  public optionsSlider: Options = {
    showTicksValues: true,
    vertical: this.isMobile,
    stepsArray: [
      { value: 1, legend: "schlecht" },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9, legend: "genau richtig" }
    ]
  };

  public optionsSliderDiffrent: Options = {
    showTicksValues: true,
    vertical: this.isMobile,
    stepsArray: [
      { value: 1, legend: "verschlechtern" },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9, legend: "verbessern" }
    ]
  };


  public weiterButtonBerufHappy = [{
    id: 'weiter', name: 'Weiter', iconClass: "fas fa-forward", onClick: () => { this.selectedItemGo('beruf_happy', this.user.beruf_happy) }
  }]

  public weiterButtonBerufEffektiv = [{
    id: 'weiter', name: 'Weiter', iconClass: "fas fa-forward", onClick: () => { this.selectedItemGo('beruf_effektiv', this.user.beruf_effektiv) }
  }]
  public weiterButtonBerufFreundschaft = [{
    id: 'weiter', name: 'Weiter', iconClass: "fas fa-forward", onClick: () => { this.selectedItemGo('beruf_diffrentTeam', this.user.beruf_diffrentTeam) }
  }]

  public weiterButtonEinkommen = [{
    id: 'weiter', name: 'Weiter', iconClass: "fas fa-forward", onClick: () => { this.selectedItemGo('einkommen', this.user.einkommen) }
  }]

  public weiterButtonAlter = [{
    id: 'weiter', name: 'Weiter', iconClass: "fas fa-forward", onClick: () => this.selectedItemGo('alter', this.user.alter)
  }]


  public weiterButton = [{
    id: 'weiter', name: 'Weiter', iconClass: "fas fa-forward", onClick: () => this.slideNext()
  }]

  public fertigButton = [{
    id: 'fertig', name: 'Fertigstellen', iconClass: "fas fa-check", onClick: () => { this.selectedItem('anfahrt', this.user.anfahrt); this.save() }
  }]


  public optionsAnfahrt: Options = {
    floor: 0,
    ceil: 120,
    vertical: this.isMobile,
    translate: (value: number): string => {

      if (value >= 60) {
        var hours = Math.floor(value / 60);
        var minutes = value % 60;

        if (hours > 1) {
          return hours + " Stunden " + minutes + " Minuten";
        } else {
          if (minutes > 1 || minutes == 0) {
            return hours + " Stunde " + minutes + " Minuten";
          } else {
            return hours + " Stunde " + minutes + " Minute";
          }
        }

      } else {
        if (value > 1 || value == 0) {
          return value + " Minuten";
        } else {
          return value + " Minute";
        }
      }

    }
  };



  public optionsEinkommen: Options = {

    floor: 0,
    ceil: 200000,
    step: 1000,
    showTicks: false,
    vertical: this.isMobile,

    customValueToPosition: (val: number, minVal: number, maxVal: number): number => {
      val = Math.sqrt(val);
      minVal = Math.sqrt(minVal);
      maxVal = Math.sqrt(maxVal);
      const range: number = maxVal - minVal;
      return (val - minVal) / range;
    },
    customPositionToValue: (percent: number, minVal: number, maxVal: number): number => {
      minVal = Math.sqrt(minVal);
      maxVal = Math.sqrt(maxVal);
      const value: number = percent * (maxVal - minVal) + minVal;
      return Math.pow(value, 2);
    },
    translate: (value: number): string => {

      if (value == 200000) {
        return value + "+ €"
      }
      return value + " €";
    }
  };

  ngOnInit(): void {
    // console.log(this.pagesMap);  

    this.setupDemopgrah()

    this.DataService.staticLoad
      .pipe(take(1))
      .subscribe(() => {

        console.log("QUESTIONS loaded", this.DataService.static)


      });


    // var questions = this.DataService.loadQuestions()



    this.user = new PollUser()


    // this.userId = this.AuthService.user.uid;
    // this.UserService.getUser(this.AuthService.user.uid).then((res) => {
    //   this.user = res ?? new Bewerber();

    //   if (!this.user.email) {
    //     this.user.email = this.AuthService.user.email
    //   }

    // });

    // const saveCookie = this.cookieService.get('setup')
    // if (saveCookie) {
    //   this.user = saveCookie as Bewerber
    // }
  }



  updateSex(value) {
    console.log('updateSex', value)
    this.slideNext()
  }


  slideChanged() {
    this.slides.getActiveIndex().then((index) => {
      this.currentPageIndex = index;
      this.currentPageId = this.pages[index]?.id
      console.log('page', index);
      if (index === 1) {
        console.log(this.inputEl)
        // setTimeout(() => this.inputEl.el.setFocus(), 10);
      }
    });
  }

  nothing = () => console.log('NO');

  text = (event) => console.log('TEXT', event);


  // formUpdateTime = () => {
  //   // this.form.loading = true
  //   clearTimeout(this.typingTimer);
  //   this.typingTimer = setTimeout(() => this.formUpdate(), 1000);
  // };

  //FORM
  // get arbeitsorte() { return this.form.get('arbeitsorte') as FormArray }
  // get arbeitgeber() { return this.form.get('arbeitgeber') as FormArray }
  // get arbeitgeber0() { return this.arbeitgeber.controls[0] as FormGroup }

  save(navigate = false) {
    this.DataService.skipMissing = true
    console.log("DONE PRESSED")
    this.DataService.missing.next({ fit: [], personal: [], total: 0 })
    this.router.navigate(['/done'])
  }


  // scrollTo = (page) => scrollLeftAnimated(document.getElementsByClassName('scrolling-wrapper')[0], (document.getElementById(page)?.offsetLeft ?? 20) - 20, 500)
  scrollTo = (page, event?) => {
    if (event) {
      // console.log(event)
      event.preventDefault();
    }
    console.log(page);

    let pg = this.pagesMap[page];
    console.log(pg);
    this.slides.slideTo(pg.index);
    if (pg.focus)
      setTimeout(() => {
        document
          .getElementById(pg.focus)
          .getElementsByTagName('input')[0]
          ?.focus();
      }, 300);

    // this.saveCookie()
  };

  slidePrev() {
    this.slides.slidePrev()
    // this.saveCookie()
  }

  slideNext() {
    this.slides.slideNext()
    this.pageTop.scrollToTop();
    // this.saveCookie()
  }

  // saveCookie = () => this.cookieService.set('setup', this.user)


  //* PersonalFitSpeed



  selectedItem(key, value) {

    this.DataService.savePersonalData(key, value);

    console.log("NEXT SLIDE")
    // if (key == 'anfahrt') {
    //   this.
    // }

  }

  selectedItemGo(key, value) {

    this.DataService.savePersonalData(key, value);

    console.log("NEXT SLIDE")

    this.slideNext()

  }







  //personalFitSpeedInitSkills = () => this.personalFitSpeed.slides[5 - 1].answers = this.user.getAllSkills(['sprache', 'software'])


}

import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-theme-menu-mobile',
  templateUrl: './theme-menu-mobile.component.html',
  styleUrls: ['./theme-menu-mobile.component.scss'],
})
export class ThemeMenuMobileComponent implements OnInit {
  @Input() user
  public show = false

  constructor(private modalController: ModalController) { }

  ngOnInit() {
    if(this.user){
      this.show = true
    }
  }


  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  logout() {
    // this.authService.logout()
    this.dismiss()
  }


}

<ion-content #pageTop>

    <ion-grid *ngIf="personalFitSpeed.currentSlide < -1">
        <ion-row>
            <ng-container *ngIf="personalFitSpeed.currentSlide == -2">
                <ion-col sizeMd="5" offsetMd="1" size="6">
                    <img id="logo" src="/assets/logo/logo_color.png" class="alignEnd" />
                </ion-col>
                <!-- <ion-col size="6" sizeMd="5" class="amazonContainer">
                    <div class="amazon" *ngFor="let i of [1,1,1]">
                        <img src="/assets/img/amazon_giftcard.png" />
                    </div>
                </ion-col> -->
                <ion-col sizeMd="10" offsetMd="1" size="12" style="line-height: 1.5;">
                    <h1>building.perfect.teams – genau daran arbeiten wir gerade!</h1>

                    <h1>
                        Hilf uns dabei, unseren Schnell-Test noch besser zu machen und Daten zu sammeln
                        <!-- (nicht personenbezogen und komplett anonym). -->
                    </h1>
                    <p>
                        Mit deiner Hilfe finden wir künftig den Perfect Match – den richtigen Job und das passende Team für jeden Bewerber!
                    </p>
                    <p>Und das ganz einfach und super-schnell.</p>
                    <p>
                        Mit einem Klick gehts los, – dieser Test dauert maximal 12 Minuten.
                    </p>

                    <p>Pro Frage hast du maximal 10 Sekunden Zeit. </p>

                    <p>Beantworte die Fragen komplett intuitiv – es gibt kein „Richtig“ oder „Falsch“ und auch kein „Schlechter“ oder „Besser“. </p>

                    <h6>Vielen Dank und viel Spaß </h6>

                    <p>
                        <ion-checkbox color="primary" [(ngModel)]="acceptPrivacy"></ion-checkbox>&nbsp;
                        <a href="https://poll.karriereheld.team/agb" target="_blank">Datenschutzbedingungen</a> akzeptieren
                        <ui-select *ngIf="!loading" [items]="personalFitSpeed.startButton"></ui-select>
                        <ui-select *ngIf="loading" [items]="[{id: 'start', name: 'Start', iconClass: 'fas fa-spinner-third fa-spin'}]">
                        </ui-select>
                </ion-col>
            </ng-container>
        </ion-row>
    </ion-grid>
    <ion-slide *ngIf="personalFitSpeed.currentSlide > -2">
        <!-- <div class="progress" *ngIf="personalFitSpeed.currentSlide>=0">
        <div class="progress-value"
            [style.width]="((this.personalFitSpeed.slides?personalFitSpeed.currentSlide/this.personalFitSpeed.slides.length:0)*100)*0.75+'%'">
        </div>
    </div> -->
        <div class="progressCounter" *ngIf="personalFitSpeed.currentSlide>=0">
            <p>Noch maximal</p>
            <h1>{{fancyTimeFormat(this.timeLeft)}}</h1>
            <p>Minuten</p>

        </div>
        <ion-grid>
            <ion-row>
                <ion-col size="12" class="text-center" *ngIf="personalFitSpeed.currentSlide >= 0">
                    <h1 [class.rot]="timewarning"><i class="fas fa-stopwatch"></i> {{personalFitSpeed.timer}}</h1>
                </ion-col>
                <ng-container *ngFor="let slide of personalFitSpeed.slides">
                    <ion-col size="12" [style.display]="personalFitSpeed.currentSlide == slide.id?'block':'none'">
                        <h1>{{slide.question}}</h1>
                        <ui-select [items]="slide.answers" (valueChange)="personalFitSpeedSelect(slide, $event)">
                        </ui-select>
                    </ion-col>
                </ng-container>
                <ion-col size="12" *ngIf="personalFitSpeed.currentSlide == -1">
                    <h1>Das wars schon!</h1>
                    Nur noch eine kurze Frage
                    <!-- <ui-select [items]="personalFitSpeed.weiterButton"></ui-select> -->

                    <ui-select routerLink="/feedback" [items]="[{id: 'feedback', name: 'Weiter', iconClass: 'fas fa-comments'}]"></ui-select>

                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-slide>
</ion-content>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-participation',
  templateUrl: './privacy-participation.component.html',
  styleUrls: ['./privacy-participation.component.scss'],
})
export class PrivacyParticipationComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { ToastService } from 'src/app/shared/toast.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  public feedback
  public done 
  
  constructor(private router: Router, private DataService: DataService, private toastService:ToastService) { }

  ngOnInit() { }

  saveFeedback() {
    //this.router.navigate(['/done'])
    this.DataService.savePersonalData("feedback", this.feedback);
    this.toastService.success({title: 'Vielen Dank ☺️'})
    this.done = true
  }

}

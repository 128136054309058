<ion-content #pageTop>
    <ng-container *ngIf="!done">
        <div style="display: flex; height: 50px; position: fixed; width: 100%; z-index: 10">

            <ng-container *ngFor="let p of pages; let i = index">
                <div class="progressBar" [class.disabled]="i+1>currentPageIndex" [class.hideMobile]="p.hideMobile"
                    *ngIf="p.icon">
                    <ion-button class="small" [class.disabled]="i>currentPageIndex">
                        <i [class]="p.icon"></i>
                    </ion-button>
                </div>
            </ng-container>
        </div>
        <!-- <div style="padding-top: 50px" (keydown.enter)="EnterToTab($event)" id="test" > -->
        <div style="padding-top: 50px" id="test">
            <ion-slides #slides (ionSlideDidChange)="slideChanged()" (ionSlideTap)="nothing()" style="width:100%"
                [options]="{cssMode: false, simulateTouch: false, shortSwipes:false}" *ngIf="user && DataService.static">

                <ion-slide id="sex">
                    <ion-grid>
                        <ion-row>
                            <ion-col size="12">
                                <h1>Geschlecht</h1>

                                <ui-select [items]="DataService.static?.sex" [(value)]="user.sex"
                                    (valueChange)="selectedItemGo('sex', user.sex)"></ui-select>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-slide>

                <ion-slide id="jahrgang">
                    <ion-grid>
                        <ion-row>
                            <ion-col size="12">
                                <h1><i class="fas fa-calendar"></i> Wie alt bist du?</h1>
                            </ion-col>
                            <ion-col sizeMd="6" offsetMd="3" size="12">
                                Alter
                                <ion-input type="number" placeholder="Alter auf der Tastatur eingeben" id="jahrgang"
                                    [(ngModel)]="user.alter" #alter
                                    (keydown.enter)="selectedItemGo('alter', user.alter)"
                                    (keydown.tab)="selectedItemGo('alter', user.alter)">
                                </ion-input>
                            </ion-col>
                            <ion-col sizeMd="6" offsetMd="3" size="12">
                                <ion-grid>
                                    <ion-row>
                                        <ion-col size="4">
                                            <div class="selectItem" (click)="addToAlter(1)">
                                                <span class="name">1</span>
                                            </div>
                                        </ion-col>
                                        <ion-col size="4">
                                            <div class="selectItem" (click)="addToAlter(2)">
                                                <span class="name">2</span>
                                            </div>
                                        </ion-col>
                                        <ion-col size="4">
                                            <div class="selectItem" (click)="addToAlter(3)">
                                                <span class="name">3</span>
                                            </div>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="4">
                                            <div class="selectItem" (click)="addToAlter(4)">
                                                <span class="name">4</span>
                                            </div>
                                        </ion-col>
                                        <ion-col size="4">
                                            <div class="selectItem" (click)="addToAlter(5)">
                                                <span class="name">5</span>
                                            </div>
                                        </ion-col>
                                        <ion-col size="4">
                                            <div class="selectItem" (click)="addToAlter(6)">
                                                <span class="name">6</span>
                                            </div>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="4">
                                            <div class="selectItem" (click)="addToAlter(7)">
                                                <span class="name">7</span>
                                            </div>
                                        </ion-col>
                                        <ion-col size="4">
                                            <div class="selectItem" (click)="addToAlter(8)">
                                                <span class="name">8</span>
                                            </div>
                                        </ion-col>
                                        <ion-col size="4">
                                            <div class="selectItem" (click)="addToAlter(9)">
                                                <span class="name">9</span>
                                            </div>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col size="4" offset="4">
                                            <div class="selectItem" (click)="addToAlter(0)">
                                                <span class="name">0</span>
                                            </div>
                                        </ion-col>
                                        <ion-col size="4">
                                            <div class="selectItem" (click)="user.alter = null">
                                                <span class="name"><i class="fas fa-backspace"></i></span>
                                            </div>
                                        </ion-col>
                                    </ion-row>
                                </ion-grid>
                            </ion-col>
                            <!-- <input type="text"> -->
                            <ion-col sizeMd="6" offsetMd="3" size="12">
                                <ui-select *ngIf="user.alter" [items]="weiterButtonAlter"></ui-select>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-slide>

                <ion-slide id="erfahrungen">
                    <ion-grid>
                        <ion-row>
                            <ion-col size="12">
                                <h1><i class="fas fa-graduation-cap"></i> Höchster Abschluss?</h1>
                                <p>Wähle deinen höchsten Bildungsgrad aus (zum aktuellen Zeitpunkt)</p>
                            </ion-col>
                            <ion-col size="12">

                                Höchster Abschluss
                                <!-- <ng-select class="fullHeight" appendTo="body" [loading]="!DataService.static" id="hoechster_abschluss" dropdownPosition="bottom" [items]="DataService.static?.hoechster_abschluss" bindLabel="name" placeholder="Bitte wählen..." bindValue="id" [(ngModel)]="user.hoechster_abschluss">
                            </ng-select> -->

                                <ui-select [items]="DataService.static?.hoechster_abschluss"
                                    [(value)]="user.hoechster_abschluss"
                                    (valueChange)="selectedItemGo('hoechster_abschluss', user.hoechster_abschluss)">
                                </ui-select>

                                <!-- <ui-select [items]="DataService.static?.hoechster_abschluss" [(value)]="user.hoechster_abschluss"></ui-select> -->
                            </ion-col>

                        </ion-row>
                    </ion-grid>
                </ion-slide>

                <ion-slide id="berufsfeld">
                    <ion-grid>
                        <ion-row>
                            <ion-col size="12">
                                <h1><i class="fas fa-caret-up"></i> In welchem Bereich arbeitest du? </h1>
                            </ion-col>
                            <ion-col size="12">
                                Wähle das passende Berufsfeld aus
                                <!-- <ui-select [items]="DataService.static?.berufsfelder" [(value)]="user.berufsfeld"
                                [custom]="true" [customMap]="DataService.staticMap?.berufsfelder"
                                (valueChange)="selectedItemGo('berufsfeld', user.berufsfeld)"></ui-select> -->
                                <ui-select [items]="DataService.static?.berufsfelder" [(value)]="user.berufsfeld"
                                    (valueChange)="selectedItemGo('berufsfeld', user.berufsfeld)"></ui-select>
                            </ion-col>
                            <!-- <ion-col size="12" *ngIf="user.berufsfeld" style="margin-top: 1rem;">
                            Stellenbezeichnung
          
                            <ui-select [items]="DataService.static?.stellenbezeichnungen[user.berufsfeld]" [(value)]="user.stellenbezeichnung" [search]="true" [customMap]="DataService.staticMap?.stellenbezeichnungen"></ui-select>
                        </ion-col> -->
                        </ion-row>
                    </ion-grid>
                </ion-slide>

                <ion-slide id="beruf_happy">
                    <ion-grid>
                        <ion-row>
                            <ion-col size="12">
                                <h1><i class="fas fa-industry"></i> Wie zufrieden bist du mit deinem Beruf?
                                </h1>

                                Entscheide dich auf der Skala zwischen 1 (schlecht) und 9 (genau richtig),

                            </ion-col>
                            <ion-col offset="1" size="10" class="slider">
                                <ngx-slider [(value)]="user.beruf_happy" [options]="optionsSlider"></ngx-slider>
                            </ion-col>
                            <ion-col size="12">


                                <p *ngIf="user.beruf_happy == 5" class="typeInfo">5 kann nicht ausgewählt werden</p>

                                <ui-select *ngIf="user.beruf_happy != 5" [items]="weiterButtonBerufHappy"></ui-select>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-slide>


                <ion-slide id="beruf_diffrentTeam">
                    <ion-grid>
                        <ion-row>
                            <ion-col size="12">
                                <h1><i class="fas fa-industry"></i> Würde ein anderes Team deine Zufriedenheit
                                    verbessern oder
                                    verschlechtern?
                                </h1>
                            </ion-col>
                            <ion-col offset="1" size="10" class="slider">
                                <ngx-slider [(value)]="user.beruf_diffrentTeam" [options]="optionsSliderDiffrent">
                                </ngx-slider>
                            </ion-col>
                            <ion-col size="12">
                                <p *ngIf="user.beruf_diffrentTeam == 5" class="typeInfo">Entscheide dich für eine Seite
                                    | 5 kann
                                    nicht ausgewählt werden</p>
                                <ui-select *ngIf="user.beruf_diffrentTeam != 5" [items]="weiterButtonBerufFreundschaft">
                                </ui-select>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-slide>


                <ion-slide id="beruf_effektiv">
                    <ion-grid>
                        <ion-row>
                            <ion-col size="12">
                                <h1><i class="fas fa-industry"></i> Wie effektiv schätzt du dein Team ein?
                                </h1>
                                Entscheide dich auf der Skala zwischen 1 (schlecht) und 9 (genau richtig)
                            </ion-col>

                            <ion-col offset="1" size="10" class="slider">


                                <ngx-slider [(value)]="user.beruf_effektiv" [options]="optionsSlider"></ngx-slider>
                            </ion-col>

                            <ion-col size="12">
                                <p *ngIf="user.beruf_effektiv == 5" class="typeInfo">5 kann nicht ausgewählt werden</p>
                                <ui-select *ngIf="user.beruf_effektiv != 5" [items]="weiterButtonBerufEffektiv">
                                </ui-select>
                            </ion-col>

                        </ion-row>
                    </ion-grid>
                </ion-slide>

                <ion-slide id="effectiveranderesteam">
                    <ion-grid>
                        <ion-row>
                            <ion-col size="12">
                                <h1>
                                    <i class="fas fa-users"></i> Glaubst du in einem anderen Team wärst du effektiver?
                                </h1>
                                <ui-select [items]="DataService.static?.effectiveranderesteam"
                                    [(value)]="user.effectiveranderesteam"
                                    (valueChange)="selectedItemGo('effectiveranderesteam', user.effectiveranderesteam)">
                                </ui-select>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-slide>






                <ion-slide id="herkunft">
                    <ion-grid>
                        <ion-row>
                            <ion-col size="12">
                                <h1>
                                    <i class="fas fa-home-lg"></i> Woher kommst du?
                                </h1>
                                <ui-select [items]="DataService.static?.herkunft" [(value)]="user.herkunft"
                                    (valueChange)="selectedItemGo('herkunft', user.herkunft)"></ui-select>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-slide>

                <ion-slide id="einkommen">
                    <ion-grid>
                        <ion-row>
                            <ion-col size="12">

                                <h1>
                                    <i class="fas fa-money-bill-wave"></i> Wie viel verdienst du im Jahr?
                                </h1>
                                <p class="typeInfo ">Es ist uns nicht möglich diese Daten deiner Person zuzuordnen
                                </p>
                            </ion-col>

                            <!-- <ui-select [items]="DataService.static?.einkommen " [(value)]="user.einkommen "
                                    (valueChange)="selectedItemGo( 'einkommen', user.einkommen) "></ui-select>
                                     -->

                            <ion-col offset="1" size="10" class="slider">
                                <ngx-slider [(value)]="user.einkommen" [options]="optionsEinkommen"></ngx-slider>
                            </ion-col>
                            <ion-col size="12">
                                <ui-select [items]="weiterButtonEinkommen"></ui-select>
                            </ion-col>

                        </ion-row>
                    </ion-grid>
                </ion-slide>


                <ion-slide id="anfahrt">
                    <ion-grid>
                        <ion-row>
                            <ion-col size="12">
                                <h1>
                                    <i class="far fa-subway"></i> Wie lange braucht du durchschnittlich zur Arbeit?
                                </h1>
                            </ion-col>
                            <ion-col offset="1" size="10" class="slider">
                                <ngx-slider [(value)]="user.anfahrt" [options]="optionsAnfahrt"></ngx-slider>
                            </ion-col>
                            <ion-col size="12">
                                <ui-select *ngIf="user.anfahrt" [items]="fertigButton"></ui-select>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-slide>





            </ion-slides>
        </div>

        <!-- <ion-button *ngIf="currentPageIndex> 0" (click)="scrollTo(pages[currentPageIndex-1].id)">
                                <i class="fas fa-chevron-left"></i> Zurück</ion-button> -->
        <ion-button *ngIf="currentPageIndex > 0 && (currentPageId != 'personalfit_speed')" (click)="slidePrev()"
            style="position: fixed; bottom:80px; left:30px; z-index:1">
            <i class="fas fa-chevron-left"></i>
        </ion-button>
        <!-- 
        <ion-button *ngIf=" currentPageIndex==2" (click)="slideNext()" style="position: fixed; bottom:80px; right:30px; z-index:1" size="large">
            Weiter <i class="fas fa-chevron-right"></i></ion-button>
 -->

        <ion-button *ngIf="loading" style="position: fixed; bottom:80px; right:30px; z-index:1" size="large">
            <ui-loading [small]="true"></ui-loading>
        </ion-button>
    </ng-container>

    <ng-container *ngIf="done">
        <div style="padding: 100px 0 50px 0;">
            <h1>Geschafft 🥳</h1>

            <h4>Du kannst jetzt von Unternehmen und Personaldienstleistern gefunden werden.</h4>
            <h4>Sollte irgendetwas wichtiges passieren, informieren wir dich selbstverständlich per E-Mail.</h4>
        </div>
        <ui-select class="flex" [items]="doneOpt"></ui-select>

    </ng-container>
</ion-content>
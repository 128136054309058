import { Component } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import * as moment from 'moment'
import 'moment/locale/de'
import { AngularFireAuth } from '@angular/fire/auth';
import { DataService } from './data.service';
moment.locale('de')

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Karriereheld';
  public mobile = window.screen.width < 768;
  private wasOffline = false;

  constructor(public toastController: ToastController, private dataService: DataService) {
    // this.AuthService.initAuth()
    this.dataService.initAuth()
    this.toastInit()

    window.addEventListener('online', async (e) => {
      console.log('online')
      if (this.wasOffline) {
        const toast = await this.toastController.create({ color: 'success', position: 'top', duration: 2000, header: 'Internetverbindung wiederhergestellt 😍' })
        toast.present();
      }
      this.wasOffline = false;
    });
    window.addEventListener('offline', async (e) => {
      console.log('offline')
      const toast = await this.toastController.create({
        color: 'danger', position: 'top', duration: 4000, header: 'Oh nein! Keine Internetverbindung gefunden.', message: 'Bitte verbinde dich wieder mit dem Internet, sonst können wir nicht weitermachen...'
      })
      toast.present()
      this.wasOffline = true;
    });
  }

  async toastInit() {
    const toast = await this.toastController.create({
      duration: 1, header: '', cssClass: 'hidden'
    })
    toast.present()
  }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Sentry.init({
//   dsn: "https://adc28224c98c4eb898926e44b4010f3b@o161742.ingest.sentry.io/5456183",
//   integrations: [
//     new Integrations.BrowserTracing({
//       tracingOrigins: ["https://app.karriereheld.me"],
//       // tracingOrigins: ["localhost", "https://app.karriereheld.me"],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

import { Options } from '@angular-slider/ngx-slider';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Bewerber, PollUser } from '../../models/user.model';
import { NavController } from '@ionic/angular';
import { ToastService } from '../../shared/toast.service';
import { DataService } from 'src/app/data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';

import { RouterModule, Routes } from '@angular/router';

@Component({
  selector: 'app-fit',
  templateUrl: './fit.component.html',
  styleUrls: ['./fit.component.scss'],
})
export class FitComponent implements OnInit {
  public loading
  public acceptPrivacy
  @ViewChild('pageTop') pageTop;

  public personalFitSpeed = {
    slides: [],
    timer: 10,
    currentSlide: -2,
    interval: null,
    preferredSkills: [],
    startButton: [{
      id: 'start', name: 'Start', iconClass: "fas fa-play", onClick: () => {
        if (!this.acceptPrivacy) {
          this.ToastService.error({ title: 'Datenschutzbedingungen müssen akzeiptiert werden' })
          return
        }
        this.personalFitSpeedStart()
      }
    }],
    weiterButton: [{
      id: 'weiter', name: 'Weiter', iconClass: "fas fa-forward", onClick: () => this.router.navigate(['/demographic'])
    }]
  };
  missing: any;


  public createSlides() {
    /*
        var slides = [];
        for(let index in this.DataService.staticQuestions){
    
          let question = this.DataService.staticQuestions[index];
          let answers = question.answers;
        
          let selectID = ""
          for (let indexa in answers){
            let answer = answers[indexa];
            selectID = selectID + String(answer).toLowerCase()
          }
    
          let answersArray = [];
          for (let indexa in answers){
    
            let answer = answers[indexa];
    
            let answerID = String(answer).toLowerCase();
    
            let mapAnswer = {
              id: answerID, 
              name: String(answer),
              onClick: () => this.personalFitSpeedSelect(selectID, answerID)
            }
    
            answersArray.push(mapAnswer)
          }
    
    
          let questionMap = {
            id: index,
            question: question.question,
            answers: answersArray
          }
    
    
          console.log("question", question)
    
    
          console.log("questionMap", questionMap)
    
          slides.push(question)
        }
    */
    if (this.DataService.staticQuestions && this.missing) {
      const missingMap = {}
      for (let m of this.missing?.fit ?? []) {
        missingMap[m] = true
      }
      this.personalFitSpeed.slides = []
      console.log(this.DataService.staticQuestions)
      let count = 0
      for (let q of this.DataService.staticQuestions) {
        if (missingMap[q.questionID]) {
          q.id = count
          this.personalFitSpeed.slides.push(q)
          ++count
        }
      }
      if(this.personalFitSpeed.slides?.length < 1){
        this.router.navigate(['/demographic'])
      }
      this.loading = false
    }
    // this.personalFitSpeed.slides = this.DataService.staticQuestions
    console.log(this.personalFitSpeed.slides)
  }


  public value: number = 5;
  public optionsSlider: Options = {
    showTicksValues: true,
    stepsArray: [
      { value: 1, legend: "Very poor" },
      { value: 2 },
      { value: 3, legend: "Fair" },
      { value: 4 },
      { value: 5, legend: "Average" },
      { value: 6 },
      { value: 7, legend: "Good" },
      { value: 8 },
      { value: 9, legend: "Excellent" }
    ]
  };



  constructor(
    public DataService: DataService,
    private ToastService: ToastService,
    private router: Router,
    public firestore: AngularFirestore,
    private navCtrl: NavController) { }

  ngOnInit() {

    this.loading = true
    this.DataService.staticQuestionsLoad
      .pipe(take(1))
      .subscribe(() => {
        this.createSlides();
      });

    this.DataService.missing.subscribe((res: any) => {
      console.log('mmmm', res)
      this.missing = res
      this.createSlides();
      if (res) {
        if (res.total == 0) {
          this.router.navigate(['/done'])
        } else if (res.fit.length == 0) {
          this.router.navigate(['/demographic'])
        }
      }
    })

    this.user = new PollUser()

  }
  public user: PollUser;


  public start;

  public timeLeft;

  public maxInterval
  startGlobalTimer() {



    console.log(this.personalFitSpeed.slides.length)
    console.log(this.personalFitSpeed.timer)

    var maxTimeNeeded = this.personalFitSpeed.slides.length * 10

    this.timeLeft = maxTimeNeeded
    var startGlobalTime = Date.now();

    this.maxInterval = setInterval(() => {
      this.timeLeft--;
      console.log(this.timeLeft)
      console.log(this.fancyTimeFormat(this.timeLeft))
      var delta = Date.now() - startGlobalTime; // milliseconds elapsed since start

    }, 1000)

  }

  public fancyTimeFormat(duration) {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }


  personalFitSpeedStart() {

    this.startGlobalTimer()
    this.start = new Date().getTime();
    this.personalFitSpeed.currentSlide = 0
    this.personalFitSpeed.timer = 10
    this.personalFitSpeedTimer()
  }

  public timewarning = false

  personalFitSpeedTimer() {
    this.personalFitSpeed.interval = setInterval(() => {
      if (this.personalFitSpeed.timer > 0) {
        --this.personalFitSpeed.timer
      } else {
        this.timeLeft = (this.personalFitSpeed.slides.length - this.personalFitSpeed.currentSlide) * 10
        this.personalFitSpeed.timer = 10
        this.timewarning = true
        //this.personalFitSpeedNextSlide()
      }
    }, 1000)
  }

  private personalFitSpeedNextSlide() {
    ++this.personalFitSpeed.currentSlide;

    this.timewarning = false
    this.start = new Date().getTime();

    this.personalFitSpeed.timer = 10

    this.timeLeft = (this.personalFitSpeed.slides.length - this.personalFitSpeed.currentSlide) * 10

    if (this.personalFitSpeed.slides.length == this.personalFitSpeed.currentSlide) {
      this.personalFitSpeedSkillsDone()
    } else { }
  }

  public personalFitSpeedSkillsDone() {
    this.personalFitSpeed.currentSlide = -1

    if (this.personalFitSpeed.interval) {
      clearInterval(this.personalFitSpeed.interval);
    }

    if (this.maxInterval) {
      clearInterval(this.maxInterval);
    }
  }

  personalFitSpeedSelect(slide, event) {
    var end = new Date().getTime();
    var time = end - this.start;

    console.log("TIME needed", time)

    //this.user.personal_fit[key] = value
    this.personalFitSpeedNextSlide()
    this.pageTop.scrollToTop();
    this.DataService.saveFitQuestion(slide.questionID, event, time);
  }




}
